import axios from 'axios'
import GA4Handler from '../ga4_support/GA4Handler'
import { DataLayerNonLinkClickElementAction } from '../ga4_support/interfaces'

const msgLookup = {
  en: {
    success: {
      ccpa_delete:
        'Success!\nThis confirms your "Delete My Data" request. You should receive an email confirming we got your request shortly',
      ccpa_request:
        'Success!\nThis confirms your "Give Me My Data" request. You should receive an email confirming we got your request shortly',
      feedback: 'Success!\nThis confirms your feedback was sent.'
    },
    error: 'Error\nSorry we are unable to process your request right now.'
  },
  'en-ph': {
    success: {
      ccpa_delete:
        'Success!\nThis confirms your "Delete My Data" request. You should receive an email confirming we got your request shortly',
      ccpa_request:
        'Success!\nThis confirms your "Give Me My Data" request. You should receive an email confirming we got your request shortly',
      feedback: 'Success!\nThis confirms your feedback was sent.'
    },
    error: 'Error\nSorry we are unable to process your request right now.'
  },
  es: {
    success: {
      ccpa_delete:
        '¡Éxito!\nEsto confirma su solicitud "Eliminar mis datos". Debería recibir un correo electrónico confirmando que recibimos su solicitud en breve',
      ccpa_request:
        '¡Éxito!\nEsto confirma su solicitud "Proporcione mis datos" . Debería recibir un correo electrónico confirmando que recibimos su solicitud en breve',
      feedback: 'Éxito!\nEsto confirma que se enviaron sus comentarios.'
    },
    error: 'Error\nLo sentimos, no podemos procesar su solicitud en este momento.'
  },
  pt: {
    success: {
      ccpa_delete:
        'Sucesso!\nEsta mensagem confirma a sua solicitação para ‘Apagar os meus dados’. Você receberá um e-mail em breve, confirmando que recebemos a sua solicitação',
      ccpa_request:
        'Sucesso!\nEsta mensagem confirma a sua solicitação para ‘Obter os meus dados’. Você receberá um e-mail em breve, confirmando que recebemos a sua solicitação',
      feedback: 'Sucesso!\nEsta mensagem confirma que o seu feedback foi enviado.'
    },
    error: 'Erro\nDesculpe, mas não podemos processar a sua solicitação neste momento.'
  },
  hi: {
    success: {
      ccpa_delete:
        'सफलता!\nयह आपके “मेरा डाटा हटाएं” अनुरोध की पुष्टि करता है। हमें आपका अनुरोध मिल गया है और इसकी पुष्टि के लिए जल्द ही आपको एक ईमेल प्राप्त होना चाहिए‘, डाटा प्राप्त करें',
      ccpa_request:
        'सफलता!\nयह आपके “मुझे मेरा डाटा प्रदान करें” अनुरोध की पुष्टि करता है। हमें आपका अनुरोध मिल गया है और इसकी पुष्टि के लिए जल्द ही आपको एक ईमेल प्राप्त होना चाहिए‘, प्रतिक्रिया दें',
      feedback: 'सफलता!\nयह पुष्टि करता है कि आपकी प्रतिक्रिया भेज दी गयी है।'
    },
    error: 'त्रुटि\nक्षमा करें, हम अभी आपके अनुरोध पर कार्यवाही करने में असमर्थ हैं।'
  },
  de: {
    success: {
      ccpa_delete:
        'Erfolg!\nDies bestätigt Ihre "Meine Daten löschen"-Anforderung. Sie sollten in Kürze eine E-Mail erhalten, die bestätigt, dass wir Ihre Anfrage erhalten haben',
      ccpa_request:
        'Erfolg!\nDies bestätigt Ihre "Geben Sie mir meine Daten"-Anfrage. Sie sollten in Kürze eine E-Mail erhalten, die bestätigt, dass wir Ihre Anfrage erhalten haben',
      feedback: 'Erfolg!\nDies bestätigt, dass Ihr Feedback gesendet wurde.'
    },
    error: 'Fehler\nLeider können wir Ihre Anfrage derzeit nicht bearbeiten.'
  },
  it: {
    success: {
      ccpa_delete:
        'Operazione riuscita!\nQuesto conferma la tua richiesta "Elimina i miei dati". Dovresti ricevere un e-mail di conferma che abbiamo ricevuto la tua richiesta a breve',
      ccpa_request:
        'Successo!\nQuesto conferma la tua richiesta "Dammi i miei dati". Dovresti ricevere un e-mail di conferma che abbiamo ricevuto la tua richiesta a breve',
      feedback: 'Success!\nThis confirms your feedback was sent.'
    },
    error: 'Errore\nSiamo spiacenti, al momento non siamo in grado di elaborare la tua richiesta.'
  }
}

const showCcpaMessage = (
  response: 'error' | 'success',
  type?: 'ccpa_delete' | 'ccpa_request' | 'feedback'
) => {
  // Remove any prior flash msgs
  const existingFlash = document.querySelector('form p.flash')
  if (existingFlash) {
    existingFlash.remove()
  }

  const lang = document.querySelector('html').lang

  // Build the msg and insert it into the form
  const el = document.createElement('p')
  el.className = `flash ${response}`
  el.innerText = response === 'error' ? msgLookup[lang][response] : msgLookup[lang][response][type]
  const target = document.querySelector('form button')
  document.querySelector('form').insertBefore(el, target)
}

// @todo I don't think this needs to be async.
const ccpaSubmitHandler = async (serviceTarget: string, event) => {
  event.preventDefault()

  const form = event.target
  const replyToEmail = form['email-input'].value
  const mp_id = localStorage.getItem('int_mp_uuID')
  const html = document.querySelector('html')
  const platform = html.getAttribute('data-mp-platform')
  const environment = html.getAttribute('data-gtm-env')
  const { send_email_url } = (window as any).mp_globals.env

  // Some  semo_static forms has different fields
  let plainTextContent = ''
  if (serviceTarget === 'feedback') {
    // this supports the feedback forms
    const feedback = form['feedback-input'].value
    plainTextContent = `mp_id:${mp_id}\nfeedback:${feedback}\nemail:${replyToEmail}`
  } else {
    // This supports the ccpa_delete and ccpa_request forms
    const name = form['name-input'].value
    const state = form['state-input'].value
    const country = form['country-input']?.value

    plainTextContent = `mp_id:${mp_id}\nname:${name}\nemail:${replyToEmail}`

    if (state) plainTextContent = `${plainTextContent}\nstate:${state}`
    if (country) plainTextContent = `${plainTextContent}\ncountry:${country}`
  }

  try {
    await axios.post(send_email_url, {
      serviceTarget,
      plainTextContent,
      replyToEmail,
      environment,
      platform
    })
    showCcpaMessage('success', serviceTarget as any)
    form.reset()
  } catch (error) {
    console.error(`Error for ccpaSubmitHandler: ${error}`)
    showCcpaMessage('error')
  }
}

// @todo I don't think this needs to be async.
const ccpaSubmitHandlerForDelete = async event => {
  // @todo-ga4 Need additional properties.
  const data = {
    event: DataLayerNonLinkClickElementAction.impression_click,
    element: 'delete_my_data',
    element_action: 'form_submit'
  }

  GA4Handler.pushObject('ccpaSubmitHandlerForDelete', data)

  ccpaSubmitHandler('ccpa_delete', event)
}

// @todo I don't think this needs to be async.
const ccpaSubmitHandlerForRequest = async event => {
  const data = {
    event: DataLayerNonLinkClickElementAction.impression_click,
    element: 'give_me_my_data',
    element_action: 'form_submit'
  }

  GA4Handler.pushObject('ccpaSubmitHandlerForRequest', data)

  ccpaSubmitHandler('ccpa_request', event)
}

// @todo I don't think this needs to be async.
const ccpaSubmitHandlerForFeedback = async event => {
  ccpaSubmitHandler('feedback', event)
}

const ccpaCountrySelectHandler = event => {
  const mpStateSelect: HTMLSelectElement = document.querySelector('#state-input')

  if (event.target.value === 'United States of America') {
    mpStateSelect.removeAttribute('disabled')
    mpStateSelect.setAttribute('required', 'true')
  } else {
    mpStateSelect.removeAttribute('required')
    mpStateSelect.setAttribute('disabled', 'true')
    mpStateSelect.value = ''
  }
}

export {
  ccpaSubmitHandlerForDelete,
  ccpaSubmitHandlerForRequest,
  ccpaSubmitHandlerForFeedback,
  ccpaCountrySelectHandler
}
