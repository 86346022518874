import axios from 'axios'
import { neptuneDebug } from '../../../utilities'

// Time to cache location in localStorage (in milliseconds).
const LOCATE_CACHE_TIME = 1000 * 60 * 60 * 48 // 48 hours

interface IPLocateInterface {
  ip: string

  buildUrl(): string
  processResponse(any): IPLocateResult
  search(): Promise<IPLocateResult>
}

export interface IPLocateResult {
  latitude: number
  longitude: number
  countryCode: string
  countryName: string
  cacheTimestamp?: number
}

class IPLocateBase implements IPLocateInterface {
  public ip: string
  public userAgent: string

  public async search(): Promise<IPLocateResult> {
    const url = this.buildUrl()
    let options = {}

    if (this.userAgent) {
      options = { headers: { 'User-Agent': 'curl/7.58.0' } }
    }

    const json: any = await axios.get(url, options).then(response => {
      return response.data
    })

    return this.processResponse(json)
  }

  public buildUrl(): string {
    throw new Error('must be defined by subclass')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public processResponse(json): IPLocateResult {
    throw new Error('must be defined by subclass')
  }
}

export class IPApiCo extends IPLocateBase {
  key = ''

  userAgent: 'curl/7.58.0'

  public buildUrl() {
    return 'https://ipapi.co/json/'
  }

  public processResponse(json): IPLocateResult {
    return {
      latitude: json.latitude,
      longitude: json.longitude,
      countryCode: json.country_code,
      countryName: json.country_name
    }
  }
}

export class IPApiCom extends IPLocateBase {
  // noinspection SpellCheckingInspection
  key = 'Z00gGuPRmASYgoi' // DigitalTurbine account.

  public buildUrl() {
    // noinspection HttpUrlsUsage
    const urlFree = 'http://ip-api.com/json/'
    const urlPro = 'https://pro.ip-api.com/json/'

    return this.key ? `${urlPro}?key=${this.key}` : urlFree
  }

  public processResponse(json): IPLocateResult {
    return {
      latitude: json.lat,
      longitude: json.lon,
      countryCode: json.countryCode,
      countryName: json.country
    }
  }
}

/**
 * Note: HTTPS access is not included with the free plan.
 * Note: Free plan is limited to 100 requests per cycle.
 */
export class IPStack extends IPLocateBase {
  key = '68c40353d0e71b9cc0f9091a08c696cb' // Personal free account.

  public buildUrl(): string {
    return `https://api.ipstack.com/check?access_key=${this.key}`
  }

  public processResponse(json): IPLocateResult {
    return {
      latitude: json.latitude,
      longitude: json.longitude,
      countryCode: json.country_code,
      countryName: json.country_name
    }
  }
}

const Plugin = IPApiCom

/**
 * Function wrapper that should allow us to switch providers without modifying callers.
 *
 * I've tested this and haven't seen any negative impacts of a very slow response on user experience
 * using: await Promise(resolve => setTimeout(resolve, 6000))
 */
const ipLocate = async (cacheTimestamp, override = false) => {
  const now = Date.now()

  if (override === false && cacheTimestamp && now - cacheTimestamp < LOCATE_CACHE_TIME) {
    return null
  } else {
    const lookup = await new Plugin().search()

    if (!lookup.latitude || !lookup.longitude || !lookup.countryCode || !lookup.countryName) {
      throw new Error('IPLocate failed, lookup is invalid')
    }

    lookup.cacheTimestamp = now

    return lookup
  }
}

export default ipLocate
