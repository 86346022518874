import { neptuneDebug } from '../../../utilities'
import { isSbEnabled } from '../bridge_interface/bridge_interface'
import GA4Handler from '../ga4_support/GA4Handler'
import { handleTouchEvents } from '../../../utilities/touchEvent'

// Constants.
const PILLS_ANIMATE_LS_LAST_TIMESTAMP = 'navbar_pills_last_animated'
const PILLS_ANIMATE_REPEAT_DELAY_MINUTES = 30
const app_theme = document.documentElement.getAttribute('data-mp-app-theme')

// Elements.
const getElementContainer = (): HTMLElement => document.getElementById('section-navbar-container')
const getElementNavbar = (): HTMLElement => document.getElementById('section-navbar-block')
const getElementArrow = (): HTMLElement =>
  document.querySelector('#section-navbar-container .arrow .inner')
const getElementArrowContainer = (): HTMLElement =>
  document.querySelector('#section-navbar-container .arrow')

const handleTouch = (navBarEl: HTMLElement) => {
  navBarEl.addEventListener('touchstart', () => {
    document.getElementsByClassName('root-container')[0].scrollTo({ top: 1 })
  })
}

/**
 * Setup Section Navbar interaction.
 */
export const sectionNavbarInit = () => {
  const hashId = getHashId()
  const navBarEl = getElementNavbar()
  const navBarContainerEl = getElementContainer()

  if (!navBarEl) {
    return
  }

  if (!isSbEnabled()) {
    navBarContainerEl.style.display = 'flex'
  }

  const params = new URLSearchParams(document.location.search.slice(1))
  if (params.get('isChild') === 'true') {
    navBarContainerEl.classList.add('child')
    const backButton = navBarContainerEl.querySelector('.back_button')
    backButton.addEventListener('click', () => {
      history.back()
    })
    return
  }

  handleTouch(navBarEl)

  // Pill Theme.
  if (navBarContainerEl.classList.contains('pills')) {
    if (hashId === 'home') {
      pillsAnimate()
    }

    getElementNavbar().onscroll = pillsScrollHandler // Hide the arrow once a user starts scrolling the navbar.
    getElementArrow().onclick = pillsArrowHandler // Setup arrow scroll button.
  }

  // Highlight and scroll to active tab.
  const navItems = Array.from(getElementNavbar().getElementsByTagName('li'))

  for (let i = 0; i < navItems.length; i++) {
    const li = navItems[i]

    if (li.getAttribute('data-hash-id') === hashId) {
      li.classList.add('active')

      if (i >= 3) {
        scrollLinkToCenter(li, false)
      } else {
        neptuneDebug(`NAVBAR: Skipping scroll for element ${i}`)
      }

      const ul = li.parentNode as HTMLElement
      const target = (li.nextElementSibling as HTMLElement) ?? li

      // Hide scroll arrow if last tab is active.  This has no effect if pills theme is not enabled.
      if (target === ul.lastElementChild) {
        getElementArrowContainer().style.display = 'none'
      }
    }
  }

  // Wire up link click CSS class handler.
  const links = Array.from(document.querySelectorAll('#section-navbar-block a'))
  links.forEach((l, i) => {
    ;(l as HTMLElement).addEventListener('click', navigate)
    ;(l as HTMLElement).addEventListener('click', e => {
      GA4Handler.clickHandleEvent(e)
    })
    GA4Handler.impressionHandleEvent(l as HTMLElement, {
      carousel_count: links.length,
      carousel_position: i
    })
  })

  handleTouchEvents('#section-navbar-block')
}

// Get the link identifier hashId for subsequent clicks.
function getHashId() {
  if (window.location.hash) {
    return window.location.hash.replace('#', '')
  }

  return null
}

/**
 * Animates Section Navbar links on click.
 */
function navigate(e) {
  Array.from(getElementNavbar().querySelectorAll('.active')).forEach(i => {
    i.classList.add('shrink')
    i.classList.remove('active')
    i.classList.remove('bounce')
  })

  Array.from(getElementNavbar().getElementsByTagName('li')).forEach(i => {
    i.classList.remove('active')
    i.classList.remove('bounce')
  })

  e.srcElement.parentNode.parentNode.classList.add('active')
  e.srcElement.parentNode.parentNode.classList.add('bounce')
  e.srcElement.parentNode.parentNode.classList.remove('shrink')
}

function scrollLinkToCenter(target: HTMLElement, smooth: boolean) {
  const ul = target.parentNode as HTMLElement
  const linkCenter = target.offsetWidth / 2
  const boxCenter = ul.offsetWidth / 2
  const scrollAmount = target.offsetLeft - boxCenter + linkCenter

  neptuneDebug(`ul.offsetWidth: ${ul.offsetWidth}`)
  neptuneDebug(`linkCenter: ${linkCenter}`)
  neptuneDebug(`boxCenter: ${boxCenter}`)
  neptuneDebug(`scrollAmount: ${scrollAmount}`)

  if (smooth) {
    getElementNavbar().scrollBy({
      top: 0,
      left: scrollAmount,
      behavior: 'smooth'
    })
  } else {
    if (scrollAmount > 0) {
      getElementNavbar().scrollTo(scrollAmount, 0)
    } else {
      neptuneDebug('scrollAmount is less than zero, not scrolling')
    }
  }
}

function pillsArrowHandler() {
  getElementArrow().style.display = 'none'

  getElementNavbar().scrollBy({
    top: 0,
    left: getElementNavbar().scrollWidth,
    behavior: 'smooth'
  })
}

function pillsScrollHandler() {
  getElementArrowContainer().classList.add('animated')
  getElementArrowContainer().classList.add('fadeOutRight')
}

function pillsAnimate() {
  const last = parseInt(localStorage.getItem(PILLS_ANIMATE_LS_LAST_TIMESTAMP))
  const timeout = PILLS_ANIMATE_REPEAT_DELAY_MINUTES * 60 * 1000
  if (!last || Date.now() - last > timeout) {
    getElementContainer().classList.add('animated')
    if (app_theme != 'vision-you') {
      getElementArrowContainer().style.display = 'flex'
    }
    localStorage.setItem(PILLS_ANIMATE_LS_LAST_TIMESTAMP, Date.now().toString())
  }
}

export default sectionNavbarInit
