/**
 * @file
 *
 * Create a standalone file
 *   a) setting up bridge values in local storage
 *   b) exposing any other bridge methods for use in page scripts
 *
 * Build bump.
 */
import '../utilities/polyfills'
import { GA4UserAgent } from '../../types/useragent'
import * as bridgeInterface from '../common/scripts/bridge_interface/bridge_interface'
import { refreshAllDataFromClient } from '../common/scripts/bridge_interface/client_refresh'
import CarouselLoader from '../common/scripts/carousel/CarouselLoader'
import { setDeviceGTMDataAttributes } from '../common/scripts/utilities/analytics'
import { injectScript } from '../common/scripts/utilities/DOM_manipulation'
import { lazyload } from '../common/scripts/utilities/DOM_events'
import {
  ccpaSubmitHandlerForDelete,
  ccpaSubmitHandlerForRequest,
  ccpaSubmitHandlerForFeedback,
  ccpaCountrySelectHandler
} from '../common/scripts/utilities/ccpaSubmitHandlers'
import {
  adBlockConfigure,
  adBlockConfigureGPTMultiplex,
  initPubwise,
  setupPubwise
} from '../common/scripts/pubwise_config'
import { overrideUserAgentUsingClientHints } from '../common/scripts/utilities/ua-retrofill'
import { initColorScheme } from './color_scheme'
import captureException from '../sentry/captureException'
import fireworkNormalizeEventLabel from '../common/scripts/firework_video_block'
import removeBlocks from '../common/scripts/remove_blocks'
import { getDeviceData } from '../common/scripts/bridge_interface/device_data'
import { getMode } from '../common/scripts/bridge_interface/bridge_interface'
import { ga4HandleInitConsent, ga4HandleNeptunePageView } from '../common/scripts/ga4_support'
import googleSearchBarAttributionMapper from '../common/scripts/googleSearchBarAttributionMapper'
import sectionNavbarInit from '../common/scripts/section_navigation_bar'
import openFullPageArticle from '../common/scripts/full_page_article'
import { pubwiseBiddingSetup } from '../pubwise_integration'
import Privacy from '../common/lib/Privacy'
import GA4Handler from '../common/scripts/ga4_support/GA4Handler'

// Expose queues to support GPT, prebid, and pubwise in advance of their loading.
// There's no harm in doing this on pages that don't have ads either. In those cases,
// it will just be a few global variables that never get handled, and it will ensure
// any files that have ad behaviors don't throw when there aren't ad scripts around to
// ensure a queue. NOTE: some of this is duplicated in pre_pws while we use pubwise, but
// that is fine and standard for prebid modules.

const w = window as any

w.gptadslots = []
w.googletag = w.googletag || {}
w.googletag.cmd = w.googletag.cmd || []

w.pbjs = w.pbjs || {}
w.pbjs.que = w.pbjs.que || []
w.pubwise = w.pubwise || {}

// recreate what pre_pw.js is doung
w.pubwise.cmd = w.pubwise.cmd || []
w.pubwise.que = w.pubwise.que || []
w.pubwise.observers = w.pubwise.observers || []
w.pubwise.adconfig = w.pubwise.adconfig || []
w.pubwise.enabled = true
w.pubwise.loaded = false

w.googletag = w.googletag || {}
w.googletag.cmd = w.googletag.cmd || []
w.pubwise.extra_bidder_params = w.pubwise.extra_bidder_params || {}
w.pubwise.extra_bidder_params.bids = w.pubwise.extra_bidder_params.bids || []
w.pubwise.extra_dfp_params = w.pubwise.extra_dfp_params || {}

w.adBlockConfigure = adBlockConfigure
w.adBlockConfigureGPTMultiplex = adBlockConfigureGPTMultiplex
w.captureException = captureException
w.ccpaCountrySelectHandler = ccpaCountrySelectHandler
w.ccpaSubmitHandlerForDelete = ccpaSubmitHandlerForDelete
w.ccpaSubmitHandlerForFeedback = ccpaSubmitHandlerForFeedback
w.ccpaSubmitHandlerForRequest = ccpaSubmitHandlerForRequest
w.fireworkNormalizeEventLabel = fireworkNormalizeEventLabel
w.googleSearchBarAttributionMapper = googleSearchBarAttributionMapper
w.initPubwise = initPubwise
w.openFullPageArticle = openFullPageArticle
w.impressionHandleEvent = GA4Handler.impressionHandleEvent
w.sectionNavbarInit = sectionNavbarInit
w.setupPubwise = setupPubwise
w.pubwiseBiddingSetup = pubwiseBiddingSetup
w.dataLayer = w.dataLayer || []
w.GA4Handler = GA4Handler
w.CarouselLoader = CarouselLoader

w.getMode = getMode

// Ensure ads are not rendered immediately in instances where pubwise is disabled.
// NOTE: We could put this inside of the CCPA compliance block below, but it's not a bad thing to ensure we do
// this all the time by being redundant (pre_pws contains this as well). If for example pws fails to load for some other issue
// when it is expected, this will be helpful to avoid double renders.
w.googletag.cmd.push(() => {
  w.googletag.pubads().disableInitialLoad()
})

// Expose methods on window for static pages to use
w.bridgeInterface = bridgeInterface // Is window reference used anywhere?

// Semi Static Pages may not have all the same properties
w.mp_globals = w.mp_globals || {}
w.mp_globals.helpers = w.mp_globals.helpers || {}
w.mp_globals.flags = w.mp_globals.flags || {
  allow_pubwise: true,
  allow_gpt: true,
  allow_gtm: true,
  allow_nativo: true,
  allow_playbuzzVideo: true
}
w.mp_globals.helpers.injectScript = injectScript
// Added primarily to support lazyloading below the fold ad blocks when pubwise is disabled.
w.mp_globals.helpers.lazyload = lazyload

// TODO: cleanup
bridgeInterface.setScreenUnlockCallback() // Register callback for screen unlock event
refreshAllDataFromClient(getDeviceData(w.MP, w.location.search)) // Seed LocalStorage with client data

ga4HandleInitConsent()
overrideUserAgentUsingClientHints([
  'architecture',
  'bitness',
  'brands',
  'formFactor',
  'fullVersionList',
  'model',
  'platformVersion',
  'wow64'
])
  .then(v => {
    ga4HandleNeptunePageView(new GA4UserAgent(v))
  })
  .catch(e => {
    captureException(e, 'Could not override user agent using client hints')
    ga4HandleNeptunePageView()
  })
// We are testing with duplicate parameters removed.
// passIdentifiersToDatalayer() // Seed datalayer with necessary identifiers
setDeviceGTMDataAttributes()

// Setup page level targeting.
w.googletag.cmd.push(() => {
  w.googletag.pubads().setTargeting('mode', getMode() || 'unknown')
})

/**
 * This removes the loading class which sets a width and height on the ad slot (once the slot is fully rendered).
 */
w.googletag.cmd.push(() => {
  try {
    w.googletag.pubads().addEventListener('slotOnload', (event: any) => {
      let id = 'null'

      try {
        if (!event || !event.slot) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error('slotOnload failed, event or slot is empty')
        }

        id = event.slot.getSlotElementId()

        const loading = document.getElementById(id).closest('.loading')

        if (loading) {
          loading.classList.remove('loading')
        }
      } catch (e) {
        captureException(e, `Could not remove loading class from ad slot: ${id}`)
      }
    })
  } catch (e) {
    captureException(e, 'Could not add googletag slotOnload event listener')
  }
})

// do not move this function it needs to be run as soon as universal page setup
// loads so flags can be set before the rest of the page gets run

Privacy.disableGDPR()

const dnsmiStatus = localStorage.getItem('local_mp_dnsmi')

removeBlocks(dnsmiStatus)

// Use restrictive data processing for GPT if DNSMI is set.
if (Privacy.dnsmiIsEnabled(dnsmiStatus)) {
  // Note, because this is a pubwise integration library that runs after the pre_pws
  // script, we don't need to 'seed' the googletag global object. They've already done it.
  w.googletag.cmd.push(() => {
    w.googletag.pubads().setPrivacySettings({
      restrictDataProcessing: true
    })
  })
}

initColorScheme()
